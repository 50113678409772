import React, { Component } from 'react';

class Button extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      processing: false
    }
  }
  
  render() {
    return (
      <div className={((this.state.processing) ? 'button processing' : 'button') + ' ' + this.props.className} onClick={this.props.click}>
        {this.props.label}
      </div>
    );
  }
}

export default Button;