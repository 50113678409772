export function isNumber(v) {
  const value = parseFloat(v);

  if (typeof value === 'undefined')
    return false;

  if (typeof value !== 'number')
    return false;
    
  if (value !== Number(value))
    return false;
    
  if (Number.isFinite(value) === false)
    return false;

  return true;
}

export function getCalculation(type, task) {
  switch (type) {
    case 'embedded':
      if (isNumber(task.length) && isNumber(task.soilElevation) && isNumber(task.topPipeElevation))
        return (task.length + task.soilElevation - task.topPipeElevation).toFixed(1);
      break;
    case 'casinglength':
      if (isNumber(task.interior) && isNumber(task.soilElevation) && isNumber(task.topPipeElevation))
        return (task.interior + task.soilElevation - task.topPipeElevation).toFixed(1);
      break;
    case 'aboverock':
      if (getCalculation('drilledrock', task) < 0)
        if (isNumber(task.interior) && isNumber(task.soilElevation) && isNumber(task.topPipeElevation))
          return (task.interior + task.soilElevation - task.topPipeElevation).toFixed(1);
      
      if (isNumber(task.interior) && isNumber(task.rockElevation))
        return (task.soilElevation - task.rockElevation).toFixed(1);
      break;
    case 'drilledrock':
      if (isNumber(task.interior) && isNumber(task.rockElevation) && isNumber(task.topPipeElevation))
        return (task.interior - task.topPipeElevation + task.rockElevation).toFixed(1);
      break;
    case 'rockencasement':
      if (isNumber(task.length) && isNumber(task.rockElevation) && isNumber(task.topPipeElevation))
        return (task.length - task.topPipeElevation + task.rockElevation).toFixed(1);
      break;
    case 'socket':
      if (isNumber(task.interior) && isNumber(task.length))
        return (task.interior - task.length).toFixed(1);
      break;
    case 'pipebottom':
      if (isNumber(task.topPipeElevation) && isNumber(task.length))
        return (task.topPipeElevation - task.length).toFixed(1);
      break;
    case 'endelevation':
      if (isNumber(task.topPipeElevation) && isNumber(task.interior))
        return (task.topPipeElevation - task.interior).toFixed(1);
      break;
  }
  return '';
}