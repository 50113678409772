import React, { Component } from 'react';
import Button from '../components/Button';
import client from '../feathers';

class SendReport extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      to: '',
      subject: this.props.email.subject,
      body: this.props.email.body,
      response: ''
    }

    this.handleChange = this.handleChange.bind(this);
    //this.send = this.send.bind(this);
  }
  
  render() {
    return (
      <div className={(this.props.visible) ? 'container section send-email' : 'container section send-email bottom'}>
        <div className="email-container">
          <h3>Send Report via Email</h3>
          <div className="email-field">
            <label>To:</label>
            <input type="text" className="email-input" name="to" value={this.state.to} onChange={this.handleChange} />
          </div>
          <div className="email-field">
            <label>Subject</label>
            <input type="text" className="email-input" name="subject" value={this.state.subject} onChange={this.handleChange} />
          </div>
          <div className="email-field-area">
            <label>Body</label>
            <textarea className="email-text" name="body" value={this.state.body} onChange={this.handleChange} />
          </div>
          <div className={(this.state.response.length > 0) ? 'email-status' : 'hide'}>
            {this.state.response}
          </div>
          <div className="email-buttons">
            <a className="report-print-button report-email-button" href={'mailto:' + this.state.to + '?subject=' + encodeURIComponent(this.state.subject) + '&body=' + encodeURIComponent(this.state.body)}>Send Report</a>
            <Button className="cancel" label="Cancel" click={this.props.hideEmail} />
          </div>
        </div>
      </div>
    );
  }


  //<Button label="Send Report" click={this.send} />
  /*send() {
    console.log('sending email');

    client.service('send').create(
      {
        from: this.state.from,
        to: this.state.to,
        subject: this.state.subject,
        message: this.state.body
      }
    ).then(data => {
      console.log('data',data);
      if (data.ok) {
        this.setState({response: ''}, () => this.props.hideEmail());
      } else
        this.setState({response: data.message}, () => {
          setTimeout(() => { this.setState({response: ''}) }, 5000);
        })
    }).catch(error => {
      console.error(error);
    })*/

    /*fetch('https://api.maritimepilings.com/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        from: this.state.from,
        to: this.state.to,
        subject: this.state.subject,
        message: this.state.body
      })
    }).then(response => {
      console.log('response',response);

      if (response.ok)
        return response.json();

      throw response.json();
    }).then(data => {
      console.log('data',data);
    }).catch(error => {
      console.log('Failed server response.', error);
    })*/
  //}

  handleChange(event) {
    let name = event.currentTarget.name;

    this.setState({
      [name]: event.currentTarget.value
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.setState({
        from: this.props.email.from,
        subject: this.props.email.subject,
        body: this.props.email.body
      });
    }
  }
}

export default SendReport;