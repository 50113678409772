import React, { Component } from 'react';
import client from './feathers';

import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Project from './pages/project';

import Navigation from './components/Navigation';

import './App.css';
import './components.css';
import CreateProject from './pages/create-project';
import DailyReport from './pages/daily-report';
import Report from './pages/report';
import shortid from 'shortid';
import ConcreteCalculator from './pages/concrete-calculator';
import Loader from './pages/loader';
import ViewReport from './pages/view-report';
import SendReport from './pages/send-report';


// db
// user maritimepiles
// pass vEUt0pkSFXB9p9Vn
// mongodb+srv://maritimepiles:<password>@maritimepilings-jhogq.mongodb.net/test?retryWrites=true&w=majority

class App extends Component {
  constructor(props, context) {
    super(props, context);

    /*let generatedProjects = [];
    for (let i = 0; i < 15; i++) {
      generatedProjects.push(this.generateProject(i));
    }*/

    this.state = {
      activePanel: 'login',
      showNavigation: false,
      showCreate: false,
      showReport: false,
      showCreateReport: false,
      showReportCreator: false,
      showConcreteCalculator: false,
      project: {},
      projectList: [],
      dailyReport: -1,
      reportProject: {},
      reportStart: new Date((new Date()).toDateString()) - (1000 * 60 * 60 * 24 * 7),
      reportEnd: new Date((new Date()).toDateString()),
      loaded: false,
      vrproject: {},
      vrstart: new Date(),
      vrend: new Date(),
      vrnotes: '',
      showVr: false,
      appload: true,
      email: {},
      showSendEmail: false,
      isReport: true
    }

    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.create = this.create.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.selectReport = this.selectReport.bind(this);
    this.createReport = this.createReport.bind(this);
    this.toggleConcrete = this.toggleConcrete.bind(this);
    this.createDailyReport = this.createDailyReport.bind(this);
    this.createCustomReport = this.createCustomReport.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.handleReportViewer = this.handleReportViewer.bind(this);
    this.hideEmail = this.hideEmail.bind(this);
    this.handleEmailReport = this.handleEmailReport.bind(this);
    this.loadProjects = this.loadProjects.bind(this);

    this.loadProjects();
    this.handleReportViewer();
  }

  loadProjects() {
    client.service('projects').find()
    .then(res => {
      this.setState({
        projectList: res.data
      }, () => {
        this.setState({ loaded: true });
      });
    }).catch(err => {
      console.error(err);
    })
  }

  handleReportViewer() {
    if (typeof window.location === 'undefined') return '';

    if (window.location.pathname.includes('/reports/') || window.location.pathname.includes('/Reports/')
    || window.location.pathname.includes('/Report/') || window.location.pathname.includes('/report/')) {
      
      let id = window.location.pathname.replace('/reports/', '').replace('/report/', '').replace('/', '');
      client.service('reports').find({
        query: {
          id: id
        }
      }).then(res => {
        let data = res.data[0];

        client.service('projects').get(
          data.projectId
        ).then(res => {
          this.setState({
            vrproject: res,
            vrstart: data.start,
            vrend: data.end,
            vrnotes: data.notes,
            showVr: true
          })
        }).catch(error => {
          console.error(error);
        })
      }).catch(error => {
        console.error(error);
      })
    }
  }

  render() {
    return (
      <div className="app">
        <Loader
          visible={this.state.appload} />
        <Navigation
          toggle={this.toggle}
          visible={this.state.showNavigation} />
        <Login
          position={this.getPosition('login')}
          authenticate={this.authenticate} />
        <Dashboard
          position={this.getPosition('dashboard')}
          toggle={this.toggle}
          createCustomReport={this.createCustomReport}
          create={this.create}
          select={this.select}
          projects={this.state.projectList}
          updateProject={this.updateProject}
          toggleConcrete={this.toggleConcrete} />
        <Project
          position={this.getPosition('project')}
          toggle={this.toggle}
          project={this.state.project}
          showReport={this.showReport}
          createCustomReport={this.createCustomReport}
          createDailyReport={this.createDailyReport}
          select={this.selectReport}
          back={() => {this.setPosition('dashboard')}}
          updateProject={this.updateProject}
          toggleConcrete={this.toggleConcrete} />
        <DailyReport
          projects={this.state.projectList}
          toggle={this.toggle}
          position={this.getPosition('report')}
          project={this.state.project}
          report={this.state.dailyReport}
          back={() => {
            this.setState({dailyReport: -1});
            this.setPosition('project');
          }}
          updateProject={this.updateProject}
          toggleConcrete={this.toggleConcrete} />
        <CreateProject
          toggle={this.toggle}
          createReport={this.createReport}
          visible={this.state.showCreate}
          create={this.create}
          reloadProjects={this.loadProjects} />
        <Report
          handleEmail={this.handleEmailReport}
          isReport={this.state.isReport}
          project={this.state.reportProject}
          start={this.state.reportStart}
          end={this.state.reportEnd}
          toggle={this.toggle}
          back={() => this.setState({ showReportCreator: false })}
          visible={this.state.showReportCreator} />
        <ConcreteCalculator
          visible={this.state.showConcreteCalculator}
          toggleConcrete={this.toggleConcrete} />
        <ViewReport
          visible={this.state.showVr}
          isReport={this.state.isReport}
          project={this.state.vrproject}
          start={this.state.vrstart}
          end={this.state.vrend}
          notes={this.state.vrnotes} />
        <SendReport
          visible={this.state.showSendEmail}
          email={this.state.email}
          hideEmail={this.hideEmail} />
      </div>
    );
  }

  // buttons that show you clicked until the load is complete (spinner or something)
  // Process, click on a project, have it load then shift

  setPosition(panel) {
    this.setState({activePanel: panel});
  }

  toggle() {
    this.setState({showNavigation: !this.state.showNavigation});
  }

  toggleConcrete() {
    this.setState({showConcreteCalculator: !this.state.showConcreteCalculator});
  }

  create() {
    this.setState({showCreate: !this.state.showCreate});
  }

  createReport() {
    this.setState({showCreateReport: !this.state.showCreateReport});
  }

  createCustomReport(project, start, end, isReport = true) {
    this.setState({
      showReportCreator: !this.state.showReportCreator,
      reportProject: project,
      reportStart: (typeof start !== 'undefined') ? start : new Date((new Date()).toDateString()) - (1000 * 60 * 60 * 24 * 7),
      reportEnd: (typeof end !== 'undefined') ? end : new Date((new Date()).toDateString()),
      isReport: isReport
    });
  }

  getPosition(panel) {
    if (panel === this.state.activePanel)
      return 'show';

    switch(panel) {
      case 'login':
        return 'left';
      case 'dashboard':
        switch(this.state.activePanel){
          case 'project':
          case 'report                                                                                                      ':
            return 'left';
          default:
            return 'right';
        }
      case 'project':
        switch(this.state.activePanel){
          case 'report':
            return 'left';
          default:
            return 'right';
        }
      case 'report':
        switch(this.state.activePanel){
          default:
            return 'right';
        }
    }
  }

  authenticate(username, password) {
    this.setState({activePanel: 'dashboard'});
  }

  select(id) {
    const project = this.state.projectList.find(e => e.id === id);
    
    this.setState({
      project,
      activePanel: 'project'
    });
  }

  selectReport(id) {
    this.setState({
      dailyReport: id,
      activePanel: 'report'
    });
  }

  createDailyReport() {
    //create a blank report

    // test for existing id in project
    let project = {...this.state.project};
    let id = shortid.generate();
    console.log('create index', id);
    const report = {
      id: id,
      project: project._id,
      date: new Date(new Date().toDateString()),
      supervisor: '',
      projectType: 'piles',
      workers: [],
      tasks: [],
      notes: ''
    }
    project.reports.push({...report});

    this.updateProject(project, id);
  }

  updateProject(project, reportId = null) {
    console.log('update project', this.state.projectList);
    client.service('projects').update(
      project._id,
      project
    ).then(res => {
      let projects = [...this.state.projectList];
      projects[projects.findIndex(e => e._id === res._id)] = res;
      console.log('complete update project', projects);
      if (reportId) {
        this.setState({
          projectList: projects,
          dailyReport: reportId,
          activePanel: 'report'
        }, console.log('save'));
      } else {
        this.setState({
          projectList: projects
        }, console.log('save'));
      }
    }).catch(err => {
      console.error(err);
    })
  }

  handleEmailReport(email) {
    this.setState({
      email: email,
      showSendEmail: true
    })
  }

  hideEmail() {
    this.setState({
      showSendEmail: false
    })
  }
}

export default App;
