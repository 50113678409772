import React, { Component } from 'react';
import Button from '../components/Button';

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      username: '',
      password: '',
      notice: ''
    }

    this.onChange = this.onChange.bind(this);
    this.authenticate = this.authenticate.bind(this);
  }
  
  render() {
    return (
      <div className={'container ' + this.props.position + ' section login'}>
        <img className="login-logo" src={process.env.PUBLIC_URL + '/logo.png'} alt="Maritime Caisson & Piling Projects" />
        <input type="text"
          className="login-field"
          name="username"
          value={this.state.username}
          onChange={this.onChange}
          placeholder="Username" />
        <input type="password"
          className="login-field"
          name="password"
          value={this.state.password}
          onChange={this.onChange}
          placeholder="Password" />
        <Button className="login-button" label="Login" click={this.authenticate} />
        <div>{this.state.notice}</div>
      </div>
    );
  }

  onChange(event) {
    const target = event.currentTarget;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value });
  }

  authenticate() {
    if (this.state.username.toLowerCase() === 'projects' && this.state.password === 'Caissons2020$')
      this.props.authenticate(this.state.username, this.state.password);
    else
      this.setState({notice: 'Incorrect Login Credentials'},
        () => setTimeout(() => { this.setState({notice: ''}) }, 5000)
      );
  }
}

export default Login;