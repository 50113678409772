import React, { Component } from 'react';
import Header from '../components/Header';
import Moment from 'react-moment';
import Button from '../components/Button';

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      showProjectOptions: -1
    }

    this.showProjectOptions = this.showProjectOptions.bind(this);
    this.createCustomReport = this.createCustomReport.bind(this);
    this.archiveProject = this.archiveProject.bind(this);
  }
  
  render() {
    return (
      <div className={'container ' + this.props.position}>
        <Header toggle={this.props.toggle}
          toggleConcrete={this.props.toggleConcrete} />
        <div className="section">
          <Button className="create-project-button" label={<span className="create-project-i"><i className="far fa-plus"></i> Create Project</span>} click={this.props.create} />
          <h2>Recent Projects</h2>
          <div className="project-list">
            {this.renderProjects('active')}
          </div>
          <h2>Archived Projects</h2>
          <div className="project-list">
            {this.renderProjects('archive')}
          </div>
          <Button className="create-project-button" label={<span className="create-project-i"><i className="far fa-plus"></i> Create Project</span>} click={this.props.create} />
        </div>
      </div>
    );
  }

  renderProjects(status) {
    let results = [];
    let projects = this.props.projects.sort((a,b) => { return new Date(b.updated) - new Date(a.updated) })

    for (let i = 0; i < projects.length; i++) {
      const project = projects[i];
      
      if (project.status === status)
        results.push(
          <div className="project-item" onClick={() => this.props.select(project.id)} key={'project-' + project.status + '-' + i}>
            <div>
              <div className="item-name">{project.name}</div>
              <div className="item-updated">Latest Report: {(typeof project.reports !== 'undefined' && project.reports.length > 0) ? <Moment format="MMMM DD YYYY">{project.reports.sort((a,b) => { return new Date(b.date) - new Date(a.date)})[0].date}</Moment> : 'None'}</div>
            </div>
            <div className="item-menu" onClick={e => this.showProjectOptions(e, i)}>
              <i className="far fa-ellipsis-v"></i>
              <div className={(i === this.state.showProjectOptions) ? 'item-menu-area show' : 'item-menu-area'}>
                <div className="item-menu-option-button" onClick={(e) => this.createCustomReport(e, project)}>Custom Report</div>
                <div className="item-menu-option-button" onClick={(e) => this.archiveProject(e, project)}>{(project.status === 'archive') ? 'Activate' : 'Archive'} Project</div>
              </div>
            </div>
          </div>
        );
    }

    return results;
  }

  showProjectOptions(event, index) {
    event.stopPropagation();

    if (index !== this.state.showProjectOptions)
      this.setState({showProjectOptions: index});
    else
      this.setState({showProjectOptions: -1});
  }

  createCustomReport(event, project) {
    event.stopPropagation();
    this.setState({showProjectOptions: -1});

    this.props.createCustomReport(project);
  }

  archiveProject(event, project) {
    event.stopPropagation();
    this.setState({showProjectOptions: -1});

    if (project.status === 'archive')
      project.status = 'active';
    else
      project.status = 'archive';

    this.props.updateProject(project);
  }
}

export default Dashboard;