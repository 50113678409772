
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import config from './config';

const socket = io(config.api);
const client = feathers();

client.configure(socketio(socket));

export default client;
