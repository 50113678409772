import React, { Component } from 'react';
import PrintableReport from '../components/printableReport';
import Timesheet from '../components/timesheet';

class ViewReport extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      project: {},
      start: this.props.start,
      end: this.props.end,
      isReport: this.props.isReport
    }
  }
  
  render() {
    return (
      <div className={(this.props.visible) ? 'container section view-report' : 'container section hide view-report'}>
        <PrintableReport project={this.state.project} start={this.state.start} end={this.state.end} />
        <Timesheet project={this.state.project} start={this.state.start} end={this.state.end} />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project)
      this.setState({project: this.props.project});
    if (prevProps.start !== this.props.start)
      this.setState({start: this.props.start});
    if (prevProps.end !== this.props.end)
      this.setState({end: this.props.end});
    if (prevProps.isReport !== this.props.isReport)
      this.setState({isReport: this.props.isReport});
  }
}

export default ViewReport;
