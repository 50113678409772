import React, { Component } from 'react';

class Loader extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      visible: this.props.visible,
      message: 'Loading Report',
      loaded: false
    }
  }
  
  render() {
    return (
      <div className={this.getVisibilityState()}>
        <div className="lds-ring loader-spinner"><div></div><div></div><div></div><div></div></div>
        <div className="loader-message">{this.state.message}</div>
        <div className="loader-footer">&copy; Maritime Caissons and Piling</div>
      </div>
    );
  }

  getVisibilityState() {
    //return 'container loader';
    if (this.state.visible && this.state.loaded)
      return 'container loader fade';
    else if (this.state.visible)
      return 'container loader';
    else
      return 'container loader hide';
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true }, () => {
        setTimeout(() => {
          this.setState({ visible: false });
        }, 500);
      });
    }, 500);
  }
}

export default Loader;