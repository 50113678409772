import React, { Component } from 'react';
import Button from '../components/Button';

class ConcreteCalculator extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      diameter: '',
      length: ''
    }

    this.handleChange = this.handleChange.bind(this);
  }
  
  
  render() {
    return (
      <div className={(this.props.visible) ? 'container section concrete-calc' : 'container section concrete-calc bottom'}>
        <div className="con-calc">
          <h2>Concrete Calculator</h2>
          <div className="con-calc-item">
            <label>Interior Diameter of Pipe (inches)</label>
            <input type="text" name="diameter" value={this.state.diameter} onChange={this.handleChange} />
          </div>
          <div className="con-calc-item">
            <label>Pile Length (meters)</label>
            <input type="text" name="length" value={this.state.length} onChange={this.handleChange} />
          </div>
          <div className="con-calc-item">
            <label>Results</label>
            {this.calculate()}
          </div>
          <Button className="cancel con-calc-btn" label="Close" click={this.props.toggleConcrete} />
        </div>
      </div>
    );
  }

  handleChange(event) {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    this.setState({ [name]: value });
  }

  calculate() {
    //return ((this.state.diameter / 2) * (this.state.diameter / 2)) * Math.PI * this.state.length * 0.000016 * this.state.length;
    let area = ((this.state.diameter * 0.0254 / 2) * (this.state.diameter * 0.0254 / 2)) * Math.PI;
    let volume = area * this.state.length; // meters
    return <div className="con-calc-ro">{volume.toFixed(2)} m<sup>3</sup></div>;
  }
}

export default ConcreteCalculator;