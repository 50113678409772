import React, { Component } from 'react';

class Navigation extends Component {
  render() {
    return (
      <div className={(this.props.visible) ? 'container navigation section' : 'container section navigation right'}>
        <div className="nav-header">
          <h2 className="nav-h2">Navigation</h2>
          <div className="nav-button" onClick={this.props.toggle}><i className="far fa-times"></i></div>
        </div>
        <div className="nav-list">
          <div className="nav-item" onClick={this.props.home}>Projects</div>
          <div className="nav-item">Manage Users</div>
          <div className="nav-item">Logout</div>
        </div>
      </div>
    );
  }
}

export default Navigation;