import React, { Component } from 'react';
import Header from '../components/Header';
import DayPicker from 'react-day-picker';
import Moment from 'react-moment';
import Button from '../components/Button';
import ReactQuill from 'react-quill';

import 'react-day-picker/lib/style.css';
import {isNumber, getCalculation} from '../utility';

class DailyReport extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      projects: this.props.projects,
      report: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)} : {},
      showPicker: false,
      notes: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)}.notes : ''
    }

    console.log('load report id', this.props.report);

    this.handleChange = this.handleChange.bind(this);
    this.handleTaskChange = this.handleTaskChange.bind(this);
    this.handleWorkerChange = this.handleWorkerChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.changeType = this.changeType.bind(this);
    this.save = this.save.bind(this);
    this.addWorker = this.addWorker.bind(this);
    this.addTask = this.addTask.bind(this);
    this.back = this.back.bind(this);
    this.getPreviousCaisson = this.getPreviousCaisson.bind(this);
    this.loadLastWorker = this.loadLastWorker.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
  }
  
  render() {
    if (typeof this.state.report === 'undefined') return '';

    return (
      <div className={'container report-details ' + this.props.position}>
        <Header toggle={this.props.toggle}
          toggleConcrete={this.props.toggleConcrete} />
        <div className="section">
          <div className="back-link" onClick={this.back}><i className="far fa-arrow-to-left"></i> Back to Project</div>
          <h2>Daily Report for {this.props.project.name}</h2>
          <div>
            <h2>Report Information</h2>
            <div className="report-date-container">
              <div className="report-date" onClick={this.togglePicker}><Moment format="MMMM DD YYYY">{this.state.report.date}</Moment></div>
              <div className={(this.state.showPicker) ? 'dp-drop show' : 'dp-drop'}>
                <DayPicker onDayClick={this.handleDateChange} />
              </div>
            </div>
            <div className="report-field">
              <label>Project Type</label>
              <select className="report-type" onChange={this.changeType} value={this.state.report.projectType}>
                <option value="piles">Piles</option>
                <option value="caissons">Caissons</option>
                <option value="miscellaneous">Miscellaneous</option>
              </select>
            </div>
            <div className="report-field">
              <label>Supervisor</label>
              <input type="text" name="supervisor" value={this.state.report.supervisor} onChange={this.handleChange} />
            </div>
            <div>
              <div>Workers</div>
              <Button label="Load Last Reports Workers" click={this.loadLastWorker} />
              {this.renderWorkers()}
              <Button label="+ Add Another Worker" click={this.addWorker} />
            </div>
          </div>
          <div>
            <h2>Daily Summary</h2>
            {this.renderToDateSummary()}
          </div>
          <div>
            <h2>Work Done</h2>
            {(this.state.report.projectType === 'piles') ? this.renderPilesTasks() : this.renderCaissonTasks()}
            <Button className="add-task-button" label={'Add ' + this.state.report.projectType} click={this.addTask} />
          </div>
          <div>
            <h2>Notes</h2>
            {/*<textarea className="report-notes" value={this.state.report.notes} name="notes" onChange={this.handleChange} />*/}
            <ReactQuill value={this.state.notes || ''} onChange={this.onContentChange} />
          </div>
        </div>
      </div>
    );
  }

  onContentChange(value) {
    this.setState({ notes: value }, this.save);
  }
  
  back() {
    // TODO Clear project report data
    this.setState({
      showPicker: false
    })

    this.props.back();
  }

  renderToDateSummary() {
    if (typeof this.props.project.reports === 'undefined') return '';
    if (typeof this.props.project.reports.tasks === 'undefined') return '';
    
    let pilestotalcomplete = 0, pilestotalexcavated = 0, pilestotalsplices = 0;
    let pilestodaycomplete = 0, pilestodayexcavated = 0, pilestodaysplices = 0;
    let caissonstotalcomplete = 0, caissonstotalexcavated = 0, caissonstotalsocket = 0, caissonstotaldrilled = 0;
    let caissonstodaycomplete = 0, caissonstodayexcavated = 0, caissonstodaysocket = 0, caissonstodaydrilled = 0;

    for (let i = 0; i < this.state.report.tasks.length; i++) {
      const task = this.state.report.tasks[i];

      if (task.taskType === 'piles') {
        if (isNumber(task.embedded))
          pilestodayexcavated += task.embedded;
        if (isNumber(task.splice))
          pilestodaysplices += task.splice;

        if (task.complete) pilestodaycomplete++;

      } else if (task.taskType === 'caissons') {
        if (isNumber(task.interior))
          caissonstodayexcavated += task.interior;
        caissonstodaysocket += getCalculation('socket', task);
        caissonstodaydrilled += getCalculation('drilledrock', task);

        if (task.complete) caissonstodaycomplete++;
      }
    }

    for (let index = 0; index < this.props.project.reports.length; index++) {
      const report = {...this.props.project.reports[index]};
      
      for (let i = 0; i < report.tasks.length; i++) {
        const task = report.tasks[i];

        if (task.taskType === 'piles') {
          if (isNumber(task.embedded))
            pilestotalexcavated += task.embedded;
          if (isNumber(task.splice))
            pilestotalsplices += task.splice;
  
          if (task.complete) pilestotalcomplete++;

        } else if (task.taskType === 'caissons') {
          if (isNumber(task.interior))
            caissonstotalexcavated += task.interior;
          caissonstotalsocket += getCalculation('socket', task);
          caissonstotaldrilled += getCalculation('drilledrock', task);

          if (task.complete) caissonstotalcomplete++;
        }
      }
    }

    if (this.state.report.projectType === 'piles')
      return (
        <div className={(this.state.report.projectType === 'piles') ? 'report-caisson' : 'report-caisson hide'}>
          <div className="report-field project-summary">
            <label></label>
            <div>Today</div>
            <div>Total</div>
          </div>
          <div className="report-field project-summary">
            <label>Piles Completed</label>
            <div>{pilestodaycomplete || 0}</div>
            <div>{pilestotalcomplete || 0}</div>
          </div>
          <div className="report-field project-summary">
            <label>Meters Driven</label>
            <div>{pilestodayexcavated || 0}</div>
            <div>{pilestotalexcavated || 0}</div>
          </div>
          <div className="report-field project-summary">
            <label>Splices</label>
            <div>{pilestodaysplices || 0}</div>
            <div>{pilestotalsplices || 0}</div>
          </div>
        </div>
        
      );

    return (
      <div className={(this.state.report.projectType === 'caissons') ? 'report-caisson' : 'report-caisson hide'}>
        <div className="report-field project-summary">
          <label></label>
          <div>Today</div>
          <div>Total</div>
        </div>
        <div className="report-field project-summary">
          <label>Caissons Installed</label>
          <div>{caissonstodaycomplete || 0}</div>
          <div>{caissonstotalcomplete || 0}</div>
        </div>
        <div className="report-field project-summary">
          <label>Meters Excavated</label>
          <div>{caissonstodayexcavated || 0}</div>
          <div>{caissonstotalexcavated || 0}</div>
        </div>
        <div className="report-field project-summary">
          <label>Sockets Drilled</label>
          <div>{caissonstodaysocket || 0}</div>
          <div>{caissonstotalsocket || 0}</div>
        </div>
        <div className="report-field project-summary">
          <label>Drilled Rock</label>
          <div>{caissonstodaydrilled || 0}</div>
          <div>{caissonstotaldrilled || 0}</div>
        </div>
      </div>
    );
  }

  changeType(event) {
    let report = {...this.state.report};
    report.projectType = event.currentTarget.value;
    this.setState({ report }, this.save);

  }

  handleDateChange(day) {
    let report = {...this.state.report};
    report.date = new Date(day);
    console.log('Adj Date', report, day);
    this.setState({ report: {...report} }, this.save);
    this.togglePicker();
  }

  togglePicker() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  addWorker() {
    let report = {...this.state.report};

    if (typeof report.workers === 'undefined')
      report.workers = []

    report.workers.push({
      name: '',
      count: '',
      hours: ''
    })
    this.setState({ report });
  }

  handleWorkerChange(event, index) {
    let report = {...this.state.report};
    report.workers[index][event.currentTarget.name] = event.currentTarget.value;
    this.setState({ report }, this.save);
  }

  renderWorkers() {
    if (typeof this.state.report.workers === 'undefined') {
      this.addWorker();
      return '';
    }
    let results = [];

    for (let i = 0; i < this.state.report.workers.length; i++) {
      const worker = this.state.report.workers[i];
      
      results.push(
        <div key={'worker'+i} className="report-worker-container">
          <input type="text" value={worker.name} name="name" onChange={(e) => this.handleWorkerChange(e, i)} placeholder="Name" />
          <input type="text" value={worker.title} name="title" onChange={(e) => this.handleWorkerChange(e, i)} placeholder="Job Title" />
          <input type="text" value={worker.hours} name="hours" onChange={(e) => this.handleWorkerChange(e, i)} placeholder="Hours" className="rw-number" />
          <Button label={<i className="far fa-trash-alt"></i>} click={() => this.removeWorker(i)} />
        </div>
      );
    }

    return results;
  }

  addTask() {
    let report = {...this.state.report};

    if (typeof report.tasks === 'undefined')
      report.tasks = []

    if (this.state.report.projectType === 'piles')
      report.tasks.push({
        id: '',
        taskType: 'piles',
        diameter: this.props.project.diameter,
        length: '',
        embedded: '',
        splice: '',
        cutoff: ''
      })
    else
      report.tasks.push({
        id: '',
        taskType: 'caissons',
        diameter: this.props.project.diameter,
        length: '',
        interior: '',
        cutoff: '',
        soilElevation: '',
        topPipeElevation: '',
        rockElevation: ''
      })
    this.setState({ report });
  }

  removeTask(index) {
    let report = {...this.state.report};

    report.tasks.splice(index, 1);

    this.setState({ report }, this.save);
  }

  removeWorker(index) {
    let report = {...this.state.report};

    report.workers.splice(index, 1);

    this.setState({ report }, this.save);
  }

  toggleTask(selector, index) {
    let report = {...this.state.report};
    report.tasks[index][selector] = !report.tasks[index][selector];
    this.setState({ report }, this.save);
  }

  handleTaskChange(event, index) {
      let report = {...this.state.report};
      let prevVal = (' ' + report.tasks[index][event.currentTarget.name]).slice(1);

      if (event.currentTarget.value.includes('.', event.currentTarget.value.length-1))
        report.tasks[index][event.currentTarget.name] = event.currentTarget.value;
      else if (isNumber(event.currentTarget.value))
        report.tasks[index][event.currentTarget.name] = parseFloat(event.currentTarget.value);
      else if (event.currentTarget.value === '-')
        report.tasks[index][event.currentTarget.name] = event.currentTarget.value;
      else
        report.tasks[index][event.currentTarget.name] = '';

      if (prevVal != report.tasks[index][event.currentTarget.name])
        this.setState({ report }, this.save);
  }

  getPreviousCaisson(event) {
    let report = {...this.state.report};
    const project = {...this.props.project};
    // if the caisson exists and wasn't complete, load it
    if (report.projectType === 'caissons') {
      // get a list of all reports before this one, that are caisson reports
      const list = project.reports.filter(e => e.projectType === 'caissons');
      let caissonList = [];
      // get all caissons and the date
      for (let i = 0; i < list.length; i++) {
        const report = list[i];
        
        for (let t = 0; t < report.tasks.length; t++) {
          const task = report.tasks[t];
          let index = caissonList.findIndex(e => e.id === task.id);

          if(index >= 0) {
            if (new Date(caissonList[index].date) < new Date(report.date)) {
              caissonList[index].date = new Date(report.date);
              caissonList[index].data = task;
            }
          } else {
            caissonList.push({
              id: task.id,
              date: new Date(report.date),
              data: task
            });
          }
        }
      }
      
      report.tasks[event.currentTarget.dataset.index] = caissonList.find(e => e.id === event.currentTarget.value).data;

      this.setState({report}, this.save);
    }
  }

  renderPilesTasks() {
    if (typeof this.state.report.tasks === 'undefined') {
      this.addTask();
      return '';
    }
    let results = [];
    for (let i = 0; i < this.state.report.tasks.length; i++) {
      const task = this.state.report.tasks[i];
      
      if (task.taskType === 'piles')
        results.push(
          <div key={'pile'+i} className="task-container">
            <div className="task-header">
              <h3>Piles #{task.id}</h3>
              <div className="task-markers">
                <div className="task-mark-button" onClick={() => this.toggleTask('complete', i)}><i className={(task.complete) ? 'fas fa-check-square' : 'far fa-square'}></i> Completed</div>
                <Button label={<i className="far fa-trash-alt"></i>} click={() => this.removeTask(i)} />
              </div>
            </div>
            <div className="task-group-container">
              <div className="task-group task-id">
                <label>ID</label>
                <input type="text" name="id" value={task.id} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-diameter">
                <label>Diameter</label>
                <input type="text" name="diameter" value={task.diameter} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-length">
                <label>Length</label>
                <input type="text" name="length" value={task.length} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-interior">
                <label>Driven</label>
                <input type="text" name="embedded" value={task.embedded} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-cutoff">
                <label>Splice</label>
                <input type="text" name="splice" value={task.splice} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-soil">
                <label>Cutoff Ele.</label>
                <input type="text" name="cutoff" value={task.cutoff} onChange={e => this.handleTaskChange(e, i)} />
              </div>
            </div>
          </div>
        )
    }
    
    return results;
  }

  renderCaissonTasks() {
    if (typeof this.state.report.tasks === 'undefined') {
      this.addTask();
      return '';
    }
    let results = [];
    for (let i = 0; i < this.state.report.tasks.length; i++) {
      const task = this.state.report.tasks[i];
      
      if (task.taskType === 'caissons')
        results.push(
          <div key={'caisson'+i} className="task-container">
            <div className="task-header">
              <h3>Caisson #{task.id}</h3>
              <div className="task-markers">
                <div className="task-mark-button" onClick={() => this.toggleTask('complete', i)}><i className={(task.complete) ? 'fas fa-check-square' : 'far fa-square'}></i> Completed</div>
                <Button label={<i className="far fa-trash-alt"></i>} click={() => this.removeTask(i)} />
              </div>
            </div>
            <div className="task-group-container">
              <div className="task-group task-id">
                <label>ID</label>
                <input type="text" data-index={i} name="id" value={task.id} onChange={e => this.handleTaskChange(e, i)} onBlur={this.getPreviousCaisson} />
              </div>
              <div className="task-group task-diameter">
                <label>Diameter</label>
                <input type="text" name="diameter" value={task.diameter} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-length">
                <label>Length</label>
                <input type="text" name="length" value={task.length} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-interior">
                <label>Interior</label>
                <input type="text" name="interior" value={task.interior} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-cutoff">
                <label>Cutoff Elev.</label>
                <input type="text" name="cutoff" value={task.cutoff} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-soil">
                <label>Soil Elev.</label>
                <input type="text" name="soilElevation" value={task.soilElevation} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-top-pipe">
                <label>Pipe Elev.</label>
                <input type="text" name="topPipeElevation" value={task.topPipeElevation} onChange={e => this.handleTaskChange(e, i)} />
              </div>
              <div className="task-group task-rock">
                <label>Rock Elev.</label>
                <input type="text" name="rockElevation" value={task.rockElevation} onChange={e => this.handleTaskChange(e, i)} />
              </div>
            </div>
            <div className="task-group-container">
              <div className="task-group task-calculated">
                <label>Driven Length</label>
                <input type="text" value={getCalculation('embedded', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Length Drilled</label>
                <input type="text" value={getCalculation('casinglength', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Above Rock</label>
                <input type="text" value={getCalculation('aboverock', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Drilled Rock</label>
                <input type="text" value={getCalculation('drilledrock', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Embedment</label>
                <input type="text" value={getCalculation('rockencasement', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Socket</label>
                <input type="text" value={getCalculation('socket', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>Pipe Bottom Elev.</label>
                <input type="text" value={getCalculation('pipebottom', task)} readOnly />
              </div>
              <div className="task-group task-calculated">
                <label>End Elev.</label>
                <input type="text" value={getCalculation('endelevation', task)} readOnly />
              </div>
            </div>
          </div>
        )
    }
    

    return results;
  }

  loadLastWorker() {
    let index = this.props.project.reports.findIndex(e => e._id === this.state.report._id);
    if (index > 0) {
      let workers = JSON.parse(JSON.stringify(this.props.project.reports[index-1].workers));
      this.state.report.workers = JSON.parse(JSON.stringify(workers));
      let report = JSON.parse(JSON.stringify(this.state.report));
      report.workers = JSON.parse(JSON.stringify(workers));
      console.log('report', report, workers);
      this.setState({ report: report }, this.save());
    }
  }

  handleChange(event) {
    let report = {...this.state.report};
    report[event.currentTarget.name] = event.currentTarget.value;
    this.setState({ report }, this.save);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.report !== this.props.report){
      console.log('load report', (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)} : {});
      this.setState({
        report: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)} : {},
        notes: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)}.notes : ''
      });
    }
    
    if (prevProps.project !== this.props.project)
      this.setState({
        report: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)} : {},
        notes: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)}.notes : ''
      });

    if (prevProps.projects !== this.props.projects)
      this.setState({
        projects: this.props.projects,
        report: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)} : {},
        notes: (typeof this.props.project.reports !== 'undefined') ? {...this.props.project.reports.find(e => e.id === this.props.report)}.notes : ''
      })
  }

  save() {
    let project = {...this.props.project};
    console.log('save report', this.state.report);
    
    let index = project.reports.findIndex(e => e.id === this.props.report);
    project.reports[index] = {...this.state.report, notes: this.state.notes};

    console.log('project', project);

    this.props.updateProject(project);
  }
}

export default DailyReport;