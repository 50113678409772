import React, { Component } from 'react';
import Moment from 'react-moment';

const daysOfTheWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

class Timesheet extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      project: this.props.project,
      start: this.props.start,
      end: this.props.end
    }
  }
  
  render() {
    return (
      <div className="report-container" ref={this.reportRef}>
        <div>
          <div className="report-doc-heading">
            <img className="report-logo" src={process.env.PUBLIC_URL + '/logo.jpg'} alt="Maritime Caisson & Piling Projects" />
            <h1>Maritime Caisson & Pilings Inc.</h1>
          </div>
          <div>
            Contract: {this.state.project.name}
          </div>
          <div>
            Director: {this.state.project.director}
          </div>
          <div>
            Dates: <Moment format="MMM DD YYYY">{this.state.start}</Moment> - <Moment format="MMM DD YYYY">{this.state.end}</Moment>
          </div>
        </div>
        {this.handleReportData()}
      </div>
    );
  }

  handleReportData() {
    if (typeof this.state.project.reports === 'undefined')
      return (<div>This project has no timesheet reports.</div>);

    if (this.state.start >= this.state.end)
      return (<div>Invalid Dates Selected.</div>);
      
    let reports = [], total = [], workers = {}, unique = [];
    let days = (this.state.end - this.state.start) / (1000 * 60 * 60 * 24);

    for (let i = 0; i < this.state.project.reports.length; i++) {
      const report = this.state.project.reports[i];
      
      if (new Date(report.date) >= new Date(this.state.start) && new Date(report.date) < new Date(this.state.end))
        reports.push(report);
    }

    console.log('reports:',reports);

    let names = {};
    let titles = [];

    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];

      const day = daysOfTheWeek[new Date(report.date).getDay()];
      workers[day] = [];

      for (let index = 0; index < report.workers.length; index++) {
        const worker = report.workers[index];

        // Calculate the total hours
        if (total.hasOwnProperty(worker.title))
          total[worker.title] += Number(worker.hours);
        else
          total[worker.title] = Number(worker.hours);

        if (!titles.includes(worker.title))
          titles.push(worker.title);

        // calculate the worker hours by day)
        workers[day].push(worker);

        // get every worker
        const name = worker.name.toLowerCase().split(' ').join('');
        if (!unique.includes(name)) {
          unique.push(name);
          names[name] = worker.name;
        }
      }
    }

    
    return (
      <div>
        {(days > 7) ? (
          <div className="ts-warning">Warning: We cannot show more then 7 days.</div>
        ) : ''}
        <h3>Total Hour Summary</h3>
        {this.renderSummary(total, titles)}

        <h3>Timesheet</h3>
        {this.renderTimesheet(workers, unique, names)}
      </div>
    );
  }

  renderTimesheetArea() {
    if (typeof this.state.project.reports === 'undefined')
      return (<div>This project has no timesheet reports.</div>);

    if (this.state.start >= this.state.end)
      return (<div>Invalid Dates Selected.</div>);

    let results = [];
    let reports = [];
    let workers = [];
    let total = [];

    const difference = new Date(this.state.end) - new Date(this.state.start);
    const days = difference / (1000*60*60*24);
    let date = this.state.start;

    for (let dayCount = 1; dayCount <= days; dayCount++) {
      // get report based on day
      let reports = [];
    
      console.log('start',new Date(this.state.start - (-1000 * 60 * 60 * 24 * (dayCount - 1))));
      console.log('end',new Date(this.state.start - (-1000 * 60 * 60 * 24 * (dayCount))- 1));

      for (let i = 0; i < this.state.project.reports.length; i++) {
        const report = this.state.project.reports[i];
        if (new Date(date) >= new Date(this.state.start - (-1000 * 60 * 60 * 24 * (dayCount - 1))) && new Date(date) < new Date(this.state.start - (-1000 * 60 * 60 * 24 * (dayCount))- 1))
          reports.push(report);
      }

      console.log('renderTimesheetArea reports', reports);
      for (let i = 0; i < reports.length; i++) {
        const report = reports[i];

        for (let index = 0; index < report.workers.length; index++) {
          const worker = report.workers[index];

          if (total.hasOwnProperty(worker.title))
            total[worker.title] += Number(worker.hours);
          else
            total[worker.title] = Number(worker.hours);
          
          workers.push({
            id: worker.name.replace(' ','').toLowerCase(),
            day: dayCount,
            name: worker.name,
            hours: Number(worker.hours)
          });
        }
      }

      date = new Date(date - (-1000 * 60 * 60 * 24 * dayCount));
    }
    console.log('renderTimesheetArea workers', workers);

    return (
      <div>
        {(days > 7) ? (
          <div className="ts-warning">Warning: We cannot show more then 7 days.</div>
        ) : ''}
        <h3>Hour Summary</h3>
        {this.renderSummary(total)}

        <h3>Timesheet</h3>
        {this.renderTimesheet(workers, days)}
      </div>
    );
  }

  renderSummary(totals, titles) {
    let results = [];
    console.log('totals:', totals);
    results.push(
      <div className="ts-total-header-summary">
        <div className="ts-total-hour-title ts-total-header-title">Title</div>
        <div className="ts-total-hour-hour ts-total-header-hour">Hours</div>
      </div>
    )

    for (let i = 0; i < titles.length; i++) {
      results.push(
        <div className="ts-total-hour-summary">
          <div className="ts-total-hour-title">{titles[i]}</div>
          <div className="ts-total-hour-hour">{totals[titles[i]]} hours</div>
        </div>
      )
      
    }

    return results;
  }

  renderTimesheet(workers, unique, names) {
    console.log('workers:', workers);
    console.log('unique:', unique);

    let results = [];
    let week = {};
    let header = [];
    header.push(<div className="ts-week-cell ts-header ts-week-name-header">Name</div>);

    // create the headers name, day1, day2, etc
    let date = this.state.start;
    while (date < this.state.end) {
      header.push(<div className="ts-week-cell ts-header ts-week-hour-header">{daysOfTheWeek[(new Date(date)).getDay()]}</div>);

      for (let i = 0; i < unique.length; i++) {
        const id = unique[i];
        if (typeof week[id] === 'undefined') week[id] = {};
        if (typeof week[id][(new Date(date)).getDay()] === 'undefined') week[id][(new Date(date)).getDay()] = [];
        
        //console.log('hour target', workers[daysOfTheWeek[date.getDay()]].find(e => e.name.toLowerCase().split(' ').join('') === id));
        if (typeof workers[daysOfTheWeek[(new Date(date)).getDay()]] !== 'undefined')
          if (typeof workers[daysOfTheWeek[(new Date(date)).getDay()]].find(e => e.name.toLowerCase().split(' ').join('') === id) !== 'undefined')
            week[id][(new Date(date)).getDay()].push(workers[daysOfTheWeek[(new Date(date)).getDay()]].find(e => e.name.toLowerCase().split(' ').join('') === id).hours);
      }

      date = new Date(date - (-1000 * 60 * 60 * 24));
    }

    let temp = [];
    for (let i = 0; i < unique.length; i++) {
      const id = unique[i];
      const days = week[id];

      temp = [];
      temp.push(<div className="ts-week-cell ts-week-name">{names[unique[i]]}</div>);

      let date = this.state.start;
      while (date < this.state.end) {
        temp.push(<div className="ts-week-cell ts-week-hours">{days[(new Date(date)).getDay()][0]}</div>);

        date = new Date(date - (-1000 * 60 * 60 * 24));
      }

      results.push(
        <div className="ts-week-worker-container">
          {temp}
        </div>
      )
    }

    return (
      <div>
        <div className="ts-week-header">
          {header}
        </div>
        {results}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start !== this.props.start) 
      this.setState({start: this.props.start});
    
    if (prevProps.end !== this.props.end) 
      this.setState({end: this.props.end});

    if (prevProps.project !== this.props.project) 
      this.setState({project: this.props.project});
  }
}

export default Timesheet;