import React, { Component } from 'react';
import Moment from 'react-moment';
import DayPicker from 'react-day-picker';
import Header from '../components/Header';
import Button from '../components/Button';

import 'react-day-picker/lib/style.css';
import { isNumber } from '../utility';

class Project extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      edit: false,
      addcontact: false,
      target: '',
      project: this.props.project,
      showPicker: false,
      adjusted: false
    }

    this.onChange = this.onChange.bind(this);
    this.targetField = this.targetField.bind(this);
    this.untargetField = this.untargetField.bind(this);
    this.renderProjectSummary = this.renderProjectSummary.bind(this);
    this.renderHours = this.renderHours.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  
  
  render() {
    if (typeof this.state.project === 'undefined') return '';

    const project = this.state.project;

    return (
      <div className={'container ' + this.props.position}>
        <Header toggle={this.props.toggle}
          toggleConcrete={this.props.toggleConcrete} />
        <div className="section">
          <div className="back-link" onClick={this.props.back}><i className="far fa-arrow-to-left"></i> Back to Project List</div>
          <div className="project-contract">{project.name}</div>
          
          <h2>Project Start Date</h2>
          <div className="report-date-container">
            <div className="report-date" onClick={this.togglePicker}><Moment format="MMMM DD YYYY">{project.start}</Moment></div>
            <div className={(this.state.showPicker) ? 'dp-drop show' : 'dp-drop'}>
              <DayPicker onDayClick={this.handleDateChange} />
            </div>
          </div>

          <h2>Project Summary</h2>
          <div className="summary-details">
            <div className="project-label">Director</div>
            <div className="project-value"><input type="text" name="director" value={project.director || ''} onChange={this.onChange} /></div>
          </div>
          <div className="summary-details">
            <div className="project-label">Pipe Diameter</div>
            <div className="project-value"><input type="text" name="diameter" value={(this.state.target !== 'diameter') ? (project.diameter || '') + ((project.diameter) ? ' inches' : '') : project.diameter} onChange={this.onChange} onFocus={e => this.targetField(e, project.diameter)} onBlur={this.untargetField} /></div>
          </div>
          <div className="summary-details">
            <div className="project-label"># of Piles</div>
            <div className="project-value"><input type="text" name="piles" value={project.piles || ''} onChange={this.onChange} /></div>
          </div>
          <div className="summary-details">
            <div className="project-label"># of Caissons</div>
            <div className="project-value"><input type="text" name="caissons" value={project.caissons || ''} onChange={this.onChange} /></div>
          </div>

          {this.renderProjectSummary()}
          
          <h2>Project Hours</h2>
          {this.renderHours()}

          <h2>Daily Reports</h2>
          <Button className="create-report-button" label="Create Daily Report" click={this.props.createDailyReport} />
          <div className="task-list">
            {this.renderReportsByWeek()}
          </div>
        </div>
      </div>
    );
  }

  renderContacts() {
    if (typeof this.props.project.contacts === 'undefined')
      return '';

    let results = [];

    for (let i = 0; i < this.props.project.contacts.length; i++) {
      const contact = this.props.project.contacts[i];
      
      results.push(
        <div key={'contact'+i} className="project-contact">
            {contact.name}
        </div>
      )
    }

    return results;
  }
  
  renderReportsByWeek() {
    if (typeof this.props.project.reports === 'undefined') return '';

    let results = [];
    let start = new Date(this.props.project.start);
    let end = new Date(start) - (-1000 * 60 * 60 * 24 * 7);
    let week = 1;
    let reportsRendered = 0;
    let safety = 0;
    let reports = this.props.project.reports.sort((a,b) => { return new Date(a.date) - new Date(b.date) });

    if (reports.length <= 0) return '';

    // Get the date limits of the reports
    let earliest = new Date(reports[0].date);
    let latest = new Date(reports[0].date);
    for (let i = 0; i < reports.length; i++) {
      if (new Date(reports[i].date) < new Date(earliest))
        earliest = new Date(reports[i].date);

      if (new Date(reports[i].date) > new Date(latest))
      latest = new Date(reports[i].date);
    }

    //get the reporting start date
    if (new Date(earliest) < new Date(start)) {
      // we have reports before the start date so we need to shift the dates back
      var diffDays = (new Date(start).getTime() - new Date(earliest).getTime()) / (1000 * 3600 * 24);
      
      start = new Date(start) - (1000 * 60 * 60 * 24 * 7 * Math.ceil(diffDays/7));
      end = new Date(start) - (-1000 * 60 * 60 * 24 * 7);
    }

    while (reportsRendered < reports.length) {
      if (safety > 10000) break;

      for (let i = 0; i < reports.length; i++) {
        const report = reports[i];

        if(new Date(report.date) >= new Date(start) && new Date(report.date) < new Date(end)) {
          results.push(
            <div key={'report'+week+'-'+i}  onClick={() => this.props.select(report.id)} className="project-report">
              <div className="report-details">
                <div><Moment format="dddd MMMM D YYYY">{report.date}</Moment></div>
                <div className="report-sub">{report.supervisor} | {report.projectType}</div>
              </div>
              <div>{this.getReportHours(report)} hours</div>
              <div className="button" onClick={e => { this.remove(e, i, report)}}><i className="far fa-trash-alt"></i></div>
            </div>
          )
          
          reportsRendered++;
        }
      }

      let clonestart = new Date(start);
      let cloneend = new Date(end);
      results.push(
        <div key={'week'+safety} className="week-of">
          <h3>Week {week} | Week of <Moment format="MMMM D YYYY">{start}</Moment></h3>
          <Button className="week-report-button" label="Timesheet" click={() => this.props.createCustomReport(this.state.project, clonestart, cloneend, false)} />
          <Button className="week-report-button" label="Report" click={() => this.props.createCustomReport(this.state.project, clonestart, cloneend)} />
        </div>
      )
      week++;
      safety++;

      start = new Date(end);
      end = new Date(end) - (-1000 * 60 * 60 * 24 * 7);
    }

    return results.reverse();
  }



  getReportHours(report) {
    let hours = 0;
    
    for (let i = 0; i < report.workers.length; i++)
      if (isNumber(report.workers[i].hours))
        hours += Number(report.workers[i].hours);
    
    return hours;
  }

  renderProjectSummary() {
    if (typeof this.state.project.reports === 'undefined') return '';
    let pilescomplete = 0;
    let pilesexcavated = 0;
    let caissonscomplete = 0;
    let caissonsexcavated = 0;

/*
      const list = project.reports.filter(e => e.projectType === 'caissons');
      let caissonList = [];
      // get all caissons and the date
      for (let i = 0; i < list.length; i++) {
        const report = list[i];
        
        for (let t = 0; t < report.tasks.length; t++) {
          const task = report.tasks[t];
          let index = caissonList.findIndex(e => e.id === task.id);

          if(index >= 0) {
            if (new Date(caissonList[index].date) < new Date(report.date)) {
              caissonList[index].date = new Date(report.date);
              caissonList[index].data = task;
            }
          } else {
            caissonList.push({
              id: task.id,
              date: new Date(report.date),
              data: task
            });
          }
        }
      }
*/

    let caissonList = [];
    for (let index = 0; index < this.state.project.reports.length; index++) {
      const report = this.state.project.reports[index];
      
      for (let i = 0; i < report.tasks.length; i++) {
        const task = report.tasks[i];

        if (task.taskType === 'piles') {
          if (isNumber(task.embedded))
            pilesexcavated += task.embedded;

          if (task.complete) pilescomplete++;
        } else if (task.taskType === 'caissons') {
          let index = caissonList.findIndex(e => e.id === task.id);

          if(index >= 0) {
            if (new Date(caissonList[index].date) < new Date(report.date)) {
              caissonList[index].date = new Date(report.date);
              caissonList[index].data = task;
            }
          } else {
            caissonList.push({
              id: task.id,
              date: new Date(report.date),
              data: task
            });
          }
        }
      }
    }

    // get the caisson data
    for (let i = 0; i < caissonList.length; i++) {
      const caisson = caissonList[i].data;
      if (isNumber(caisson.interior))
        caissonsexcavated += caisson.interior;
  
      if (caisson.complete) caissonscomplete++;
    }

    return (
      <div>
        <h2>Piles Summary</h2>
        <div className="summary-details">
          <div className="project-label">Piles Complete</div>
          <div>{pilescomplete}</div>
        </div>
        <div className="summary-details">
          <div className="project-label">Piles Remaining</div>
          <div>{(this.state.project.piles - pilescomplete < 0) ? 0 : this.state.project.piles - pilescomplete}</div>
        </div>
        <div className="summary-details">
          <div className="project-label">Meters Excavated</div>
          <div>{pilesexcavated}</div>
        </div>

        <h2>Caissons Summary</h2>
        <div className="summary-details">
          <div className="project-label">Caissons Complete</div>
          <div>{caissonscomplete}</div>
        </div>
        <div className="summary-details">
          <div className="project-label">Caissons Remaining</div>
          <div>{(this.state.project.caissons - caissonscomplete < 0) ? 0 : this.state.project.caissons - caissonscomplete}</div>
        </div>
        <div className="summary-details">
          <div className="project-label">Meters Excavated</div>
          <div>{caissonsexcavated}</div>
        </div>
      </div>
    );
  }
          

  renderHours() {
    if (typeof this.state.project.reports === 'undefined') return '';
    let results = [];
    let hours = {};

    for (let index = 0; index < this.state.project.reports.length; index++) {
      const report = this.state.project.reports[index];
      
      for (let i = 0; i < report.workers.length; i++) {
        const worker = report.workers[i];
        if (hours.hasOwnProperty(worker.title))
          hours[worker.title] += Number(worker.hours);
        else
          hours[worker.title] = Number(worker.hours);
      }
    }

    const keys = Object.keys(hours);
    let total = 0;
    for (let index = 0; index < keys.length; index++) {
      if (keys[index] !== 'undefined') {
        total += Number(hours[keys[index]]);
        results.push(
          <div key={'hoursum'+index} className="summary-details">
            <div className="project-label">{keys[index]} Hours</div>
            <div>{hours[keys[index]]} hours</div>
          </div>
        )
      }
    }

    results.unshift(
      <div key={'hoursum-0'} className="summary-details">
        <div className="project-label">Total hours</div>
        <div>{total} hours</div>
      </div>
    )

    return results;
  }

  onChange(event) {
    let project = {...this.state.project};
    project[event.currentTarget.name] = event.currentTarget.value;

    this.setState({project}, this.save);
  }

  untargetField(event, value) {
    // removes descriptive text during edit
    event.currentTarget.value = value;
  }
  
  targetField(event, value) {
    event.currentTarget.value = value;
    this.setState({ target: event.currentTarget.name})
  }
  untargetField() {
    this.setState({ target: ''})
  }

  handleDateChange(day) {
    let project = {...this.state.project};
    project.start = day;
    this.setState({ project, adjusted: !this.state.adjusted }, this.save);
    this.togglePicker();
  }

  togglePicker() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  save() {
    this.props.updateProject(this.state.project);
  }

  remove(e, index) {
    e.stopPropagation();
    console.log(this.state.project);

    let project = {...this.state.project};
    project.reports.splice(index, 1);

    console.log(project);
    this.setState({
      project: project
    }, () => {
      console.log(this.state.project);
      this.props.updateProject(this.state.project);
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project)
      this.setState({ project: this.props.project });
  }
}

export default Project;