import React, { Component } from 'react';
import client from '../feathers';
import DayPicker from 'react-day-picker';
import Moment from 'react-moment';
import Button from '../components/Button';

import 'react-day-picker/lib/style.css';

class CreateProject extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      name: '',
      director: '',
      start: new Date(new Date().toDateString()),
      diameter: null,
      piles: null,
      caissons: null,
      status: 'active',
      showPicker: false
    }

    this.onChange = this.onChange.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.createProject = this.createProject.bind(this);
  }
  
  render() {
    return (
      <div className={(this.props.visible) ? 'container create-project section' : 'container section create-project bottom'}>
        
        <div className="create-form">
          <h2>Create Project</h2>
          <div className="create-field-container">
            <div>Project Name</div>
            <input type="text"
              className="create-field"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              placeholder="CT-1234" />
          </div>
          <div className="create-field-container">
            <div>Start Date</div>
            <div className="report-date-container">
              <div className="report-date" onClick={this.togglePicker}><Moment format="MMMM DD YYYY">{this.state.start}</Moment></div>
              <div className={(this.state.showPicker) ? 'dp-drop show' : 'dp-drop'}>
                <DayPicker onDayClick={this.handleDateChange} />
              </div>
            </div>
          </div>
          <div className="create-field-container">
            <div>Director</div>
            <input type="text"
              className="create-field"
              name="director"
              value={this.state.director}
              onChange={this.onChange}
              placeholder="John Smith" />
          </div>
          <div className="create-field-container">
            <div>Number of Piles</div>
            <input type="text"
              className="create-field"
              name="piles"
              value={this.state.piles}
              onChange={this.onChange}
              placeholder="200" />
          </div>
          <div className="create-field-container">
            <div>Number of Caissons</div>
            <input type="text"
              className="create-field"
              name="caissons"
              value={this.state.caissons}
              onChange={this.onChange}
              placeholder="32" />
          </div>
          <div className="create-field-container">
            <div>Diameter of Piping</div>
            <input type="text"
              className="create-field"
              name="diameter"
              value={this.state.diameter}
              onChange={this.onChange}
              placeholder="16" />
          </div>
          <Button label="Create" click={this.createProject} />
          <Button className="cancel" label="Cancel" click={this.props.create} />
        </div>
      </div>
    );
  }

  createProject() {
    let project = {
      name: this.state.name,
      director: this.state.director,
      start: this.state.start,
      diameter: this.state.diameter,
      piles: this.state.piles,
      caissons: this.state.caissons,
      reports: [],
      contacts: []
    }

    client.service('projects').create(
      project
    ).then(res => {
      // TODO callback to load projects again
      this.props.reloadProjects();
      this.props.create();
    }).catch(error => {
      console.error(error);
    })

  }

  togglePicker() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  handleDateChange(day) {
    this.setState({ start: new Date(day) }, this.togglePicker());
  }

  onChange(event) {
    const target = event.currentTarget;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value });
  }
}

export default CreateProject;