import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import Moment from 'react-moment';
import ReactToPrint from 'react-to-print';
import PrintableReport from '../components/printableReport';

import 'react-day-picker/lib/style.css';
import Button from '../components/Button';
import client from '../feathers';
import Timesheet from '../components/timesheet';

class Report extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      project: this.props.project || {},
      start: new Date(this.props.start) || new Date((new Date()).toDateString()) - (1000 * 60 * 60 * 24 * 7),
      end: new Date(this.props.end) || new Date(new Date().toDateString()),
      showStartPicker: false,
      showEndPicker: false,
      isReport: this.props.isReport || true
    }

    this.toggleStartPicker = this.toggleStartPicker.bind(this);
    this.toggleEndPicker = this.toggleEndPicker.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.emailReport = this.emailReport.bind(this);
  }
  
  render() {
    if (typeof this.state.project === 'undefined') return '';
    return (
      <div className={(this.props.visible) ? 'container report' : 'container report bottom'}>
        <div className="section">
          <h2>Report for Project {this.state.project.name}</h2>

          <div className="report-timespan">
            <div className="create-field-container rpt-date">
              <div>Start Date</div>
              <div className="report-date-container">
                <div className="report-date" onClick={this.toggleStartPicker}><Moment format="MMMM DD YYYY">{this.state.start}</Moment></div>
                <div className={(this.state.showStartPicker) ? 'dp-drop show' : 'dp-drop'}>
                  <DayPicker onDayClick={this.handleStartDateChange} />
                </div>
              </div>
            </div>
            <div className="create-field-container rpt-date">
              <div>End Date</div>
              <div className="report-date-container">
                <div className="report-date" onClick={this.toggleEndPicker}><Moment format="MMMM DD YYYY">{this.state.end}</Moment></div>
                <div className={(this.state.showEndPicker) ? 'dp-drop show' : 'dp-drop'}>
                  <DayPicker onDayClick={this.handleEndDateChange} />
                </div>
              </div>
            </div>
          </div>

          <h2>Report Preview</h2>
          {(this.state.isReport) ? (
            <PrintableReport
              ref={self => (this.reportRef = self)}
              project={this.state.project}
              start={this.state.start}
              end={this.state.end} />
          ) : (
            <Timesheet
              ref={self => (this.timesheetRef = self)}
              project={this.state.project}
              start={this.state.start}
              end={this.state.end} />
          )}
          

          <div className="report-download-buttons">
            {(this.state.isReport) ? (
              <ReactToPrint
                trigger={() => <a className="report-print-button rpt-btn" href="#">Print Report</a>}
                content={() => this.reportRef} />
            ) : (
              <ReactToPrint
                trigger={() => <a className="report-print-button rpt-btn" href="#">Print Timesheet</a>}
                content={() => this.timesheetRef} />
            )}
            <Button className="rpt-btn" label="Send via Email" click={this.emailReport} />
            <Button className="rpt-btn cancel" label="Cancel" click={this.props.back} />
          </div>
        </div>
      </div>
    );
  }
  
  handleStartDateChange(day) {
    this.setState({ start: day });
    this.toggleStartPicker();
  }

  handleEndDateChange(day) {
    this.setState({ end: day });
    this.toggleEndPicker();
  }

  toggleStartPicker() {
    this.setState({ showStartPicker: !this.state.showStartPicker });
  }

  toggleEndPicker() {
    this.setState({ showEndPicker: !this.state.showEndPicker });
  }

  emailReport() {
    // Save report
    let report = {
      projectId: this.state.project._id,
      start: this.state.start,
      end: this.state.end
    }
    client.service('reports').create(
      report
    ).then(res => {
      let email = {
        to: '',
        from: '',
        subject: 'Report for ' + this.state.project.name + ' ' + new Date(this.state.start).toLocaleDateString() + ' - ' + new Date(this.state.end).toLocaleDateString(),
        body: 'Please view the report here: http://project.maritimepilings.com/report/' + res.id
      }

      this.props.handleEmail(email);
      // Create text email with link to the report
      // send email
      
    }).catch(error => {
      console.error(error);
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start !== this.props.start) 
      this.setState({start: this.props.start});
    
    if (prevProps.end !== this.props.end) 
      this.setState({end: this.props.end});

    if (prevProps.project !== this.props.project) 
      this.setState({project: this.props.project});

    if (prevProps.isReport !== this.props.isReport)
      this.setState({isReport: this.props.isReport});
  }
}

export default Report;