import React, { Component } from 'react';
import Moment from 'react-moment';
import {getCalculation} from '../utility';

class PrintableReport extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      project: this.props.project,
      start: this.props.start,
      end: this.props.end
    }
  }

  render() {
    return (
      <div className="report-container" ref={this.reportRef}>
        <div>
          <div className="report-doc-heading">
            <img className="report-logo" src={process.env.PUBLIC_URL + '/logo.jpg'} alt="Maritime Caisson & Piling Projects" />
            <h1>Maritime Caisson & Pilings Inc.</h1>
          </div>
          <div>
            Contract: {this.state.project.name}
          </div>
          <div>
            Director: {this.state.project.director}
          </div>
          <div>
            Dates: <Moment format="MMM DD YYYY">{this.state.start}</Moment> - <Moment format="MMM DD YYYY">{this.state.end}</Moment>
          </div>
        </div>
        {this.renderReportData()}
      </div>
    );
  }
  
  renderReportData() {
    // if the start date is older then the end date
    if (typeof this.state.project.reports === 'undefined')
      return (
        <div>This project has no reports.</div>
      );

    if (this.state.start >= this.state.end)
      return (
        <div>Invalid Dates Selected.</div>
      );

    let results = [];
    let reports = [];

    for (let i = 0; i < this.state.project.reports.length; i++) {
      const report = this.state.project.reports[i];
      
      if (new Date(report.date) >= new Date(this.state.start) && new Date(report.date) < new Date(this.state.end))
        reports.push(report);
    }

    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];

      let workers = [];

      for (let index = 0; index < report.workers.length; index++) {
        const worker = report.workers[index];
        
        workers.push(
          <div key={'prworker'+index} className="print-report-worker">
            <div className="print-report-worker-name">{worker.name}</div>
            <div className="print-report-worker-title">{worker.title}</div>
            <div className="print-report-worker-hours">{worker.hours} hours</div>
          </div>
        );
      }

      results.push(
        <h2>Report <Moment format="MMM DD YYYY">{report.date}</Moment> - <span className="report-capitalization">{report.projectType}</span></h2>
      )
      
      results.push(
        <div key={'worker-details'}>
          <h3>Work Hours</h3>
          <div className="print-report-worker">
            <div className="print-report-worker-name-title">Name</div>
            <div className="print-report-worker-title-title">Title</div>
            <div className="print-report-worker-hours-title">Hours Worked</div>
          </div>
          {workers}
        </div>
      )

      let tasks = [];

      if (report.projectType === 'piles') {
        for (let index = 0; index < report.tasks.length; index++) {
          const task = report.tasks[index];
          
          tasks.push(
            <div key={'prtask-' + index} className="report-table-item rt-piles">
              <div className="rt-id">{task.id}</div>
              <div>{task.diameter}</div>
              <div>{task.length}</div>
              <div>{task.embedded}</div>
              <div>{task.cutoff}</div>
            </div>
          );
        }

        //<div><Moment format="MMM DD YYYY">{report.date}</Moment></div>

        results.push(
          <div key={'report'+i}>
            <h3 className="report-capitalization">{report.projectType} Details</h3>
            <div className="report-table">
              <div className="report-table-header rt-piles">
                <div className="rt-id">ID</div>
                <div>Diameter</div>
                <div>Length</div>
                <div>Driven</div>
                <div>Cutoff Ele.</div>
              </div>
              {tasks}
            </div>
          </div>
        )
      } else if (report.projectType === 'caissons') {
        for (let index = 0; index < report.tasks.length; index++) {
          const task = report.tasks[index];
          
          tasks.push(
            <div key={'prtask' + index} className="report-table-item rt-caissons">
              <div className="rt-id">{task.id}</div>
              <div>{task.diameter}</div>
              <div>{task.length}</div>
              <div>{task.interior}</div>
              <div className="rt-calc">{getCalculation('embedded', task)}</div>
              <div className="rt-calc">{getCalculation('casinglength', task)}</div>
              <div className="rt-calc">{getCalculation('aboverock', task)}</div>
              <div className="rt-calc">{getCalculation('drilledrock', task)}</div>
              <div className="rt-calc">{getCalculation('rockencasement', task)}</div>
              <div className="rt-calc">{getCalculation('socket', task)}</div>
              <div>{task.cutoff}</div>
              <div>{task.soilElevation}</div>
              <div>{task.topPipeElevation}</div>
              <div>{task.rockElevation}</div>
              <div className="rt-calc">{getCalculation('pipebottom', task)}</div>
              <div className="rt-calc">{getCalculation('endelevation', task)}</div>
            </div>
          );
        }

        results.push(
          <div key={'report'+i}>
            <h3 className="report-capitalization">{report.projectType} Details</h3>
            <div className="report-table">
              <div className="report-table-header rt-caissons">
                <div className="rt-id">ID</div>
                <div>Diameter</div>
                <div>Length</div>
                <div>Interior</div>
                <div>Embedded</div>
                <div>Casing Length</div>
                <div>Above Rock</div>
                <div>Drilled Rock</div>
                <div>Rock Encasement</div>
                <div>Socket</div>
                <div>Cutoff</div>
                <div>Soil Elevation</div>
                <div>Top of Pipe</div>
                <div>Rock Elevation</div>
                <div>Pipe Bottom</div>
                <div>End Elevation</div>
              </div>
              {tasks}
            </div>
          </div>
        )
      }

      results.push(
        <div className="print-page-break">
          <h3>Daily Notes</h3>
          <div dangerouslySetInnerHTML={{__html: (report.notes || 'none')}} />
        </div>
      )
    }

    // get the reports from start date to end date

    return results;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start !== this.props.start) 
      this.setState({start: this.props.start});
    
    if (prevProps.end !== this.props.end) 
      this.setState({end: this.props.end});

    if (prevProps.project !== this.props.project) 
      this.setState({project: this.props.project});
  }
}

export default PrintableReport;